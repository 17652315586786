.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 0.3em;
}

.intl-tel-input input:focus {
  outline: none;
}

.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag {
  background-color: transparent !important;
  outline: none;
}

.intl-tel-input.allow-dropdown .flag-container:hover .selected-flag:focus {
  outline: none !important;
}

.intl-tel-input.allow-dropdown .selected-flag {
  padding-left: 15px;
}

.country-list li {
  padding: 8px 16px !important;
}

.intl-tel-input.allow-dropdown input,
.intl-tel-input.allow-dropdown input[type="text"],
.intl-tel-input.allow-dropdown input[type="tel"] {
  padding-left: 40px !important;
}

.css-1s2u09g-control {
  border-radius: 999px !important;
  padding: 2px 4px;
  border: 1px solid #cdd1cc !important;
  margin: 8px 0;
}

.css-1pahdxg-control {
  border-radius: 999px !important;
  padding: 2px 4px;
  border: 1px solid #103734 !important;
  margin: 8px 0;
  border-color: #103734 !important;
  box-shadow: none !important;
}

.StripeElement {
  width: 100%;
}

/* date picker */

.react-daterange-picker__wrapper {
  border-radius: 25px;
  padding: 7px 12px;
  font-size: 12px;
  background-color: #f2f1ef;
  border-width: 0 !important;
}

.react-daterange-picker__clear-button {
  display: none;
}

.react-daterange-picker__clear-button__icon {
  height: 16px;
  width: 16px;
  stroke: #555a5c !important;
}

.react-daterange-picker__calendar-button__icon {
  height: 16px;
  width: 16px;
  stroke: #555a5c !important;
}

.css-wd9mxf-Control {
  margin-top: 0 !important;
}
/* scroll bar */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #edb037;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #103734;
}

/* circular progressbar */

.CircularProgressbar-text {
  font-weight: 600 !important;
  font-size: 22px !important;
}

.example2 {
  box-shadow: 0px 0px 30px #88888875;
}

.hide-scrollBar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* landing page */

.second-slide .slick-next {
  position: absolute;
  top: 100%;
  right: 60px !important;
  font-size: 40px !important;
  width: 40px !important;
  height: 40px !important;
}

.second-slide .slick-prev {
  position: absolute;
  top: 100%;
  left: calc(100% - 120px) !important;
  font-size: 40px !important;
  width: 40px !important;
  height: 40px !important;
}

.testimonial-slide .slick-slide {
  margin: 0 10px;
}

/* .slick-list{padding:0 20% 0 0 !important;} */

/* .testimonial-slide .slick-list {
    margin: 0 -27px;
} */

@media only screen and (max-width: 1024px) {
  .testimonial-slide .slick-slide {
    margin: 0;
  }
}

html {
  scroll-behavior: smooth;
}


.slick-track
{
    display: flex !important;
}

.slick-slide
{
    height: inherit !important;
}

.slick-slide > div { height: 100%; }